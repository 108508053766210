import axios from 'axios'
import toastr from 'toastr'

export async function getBuilds(gameId, platform, type, store, search = null) {
  const params = new URLSearchParams()
  params.set('game', gameId)
  params.set('platform', platform)

  if (type) {
    params.set('type', type)
  }
  if (store) {
    params.set('store', store)
  }
  if (search) {
    params.set('search', search)
  }
  return (await axios.get('/portal/builds/', { params })).data
}

export async function getBuildsNext(nextUrl) {
  return (await axios.get(nextUrl)).data
}
export async function getBuild(buildId) {
  return (await axios.get(`/portal/builds/${buildId}/`)).data
}

export async function postBuild({
  game,
  type,
  platform,
  version,
  files,
  settings,
  appSealing,
  appSealingOption,
  newContents,
  fixedIssues,
  tags,
}) {
  const app_sealing_option = {}
  app_sealing_option.deploy_mode = appSealingOption.deployMode
  app_sealing_option.block_environment = appSealingOption.blockEnvironment
  app_sealing_option.external_tools = appSealingOption.externalTools
  app_sealing_option.use_custom_blacklist = appSealingOption.useCustomBlacklist
  app_sealing_option.block_work_profile = appSealingOption.blockWorkProfile
  app_sealing_option.app_signing = appSealingOption.appSigning
  app_sealing_option.service_version = appSealingOption.serviceVersion

  const data = {
    game,
    type,
    platform,
    version,
    settings,
    app_sealing: appSealing,
    app_sealing_option,
    new_contents: newContents,
    fixed_issues: fixedIssues,
    files,
    tags,
  }

  const u = []
  for (const [key, val] of Object.entries(data)) {
    if (val === undefined) u.push(key)
  }

  if (u.length) {
    throw new Error(`undefined: ${u.join(', ')}`)
  }

  return (await axios.post('/portal/builds/', data)).data
}

export async function postBuildFile(game, name) {
  const data = {
    game: game,
    name: name,
  }

  return (await axios.post('/portal/build_files/', data)).data
}

export async function postBuildTag(build, tag) {
  const data = { build, tag }

  return (await axios.post('/portal/build-tags/', data)).data
}

export async function deleteBuildTag(build, tag) {
  const data = { build }
  return (await axios.delete(`/portal/build-tags/${tag.id}/`, { data })).data
}

export async function postBuildAnalysis(buildFile, content) {
  const data = {
    build_file: buildFile,
    content: content,
  }
  return (await axios.post('/portal/build_analysis/', data)).data
}

export async function submitIpaToAppstore(buildId, IpaUuid) {
  return (
    await axios
      .post(`/portal/builds/${buildId}/submit_xcarchive_to_appstore/`, {
        uuid: IpaUuid,
      })
      .catch((e) => {
        toastr.error(e.response.data.detail)
        return e.response
      })
  ).data
}

export async function submitRebuildIOS(buildId, XCArchiveUuid) {
  return (
    await axios
      .post(`/portal/builds/${buildId}/rebuild_xcarchive_to_ipa/`, {
        uuid: XCArchiveUuid,
      })
      .catch((e) => {
        toastr.error(e.response.data.detail)
        return e.response
      })
  ).data
}

export async function submitRebuildAndroid(buildId, uuid) {
  return (
    await axios
      .post(`/portal/builds/${buildId}/rebuild_android/`, {
        uuid,
      })
      .catch((e) => {
        toastr.error(e.response.data.detail)
        return e.response
      })
  ).data
}

export async function postBuildStatus(buildId, status, comment = null) {
  const data = {
    status: status,
    comment: comment,
  }
  return (await axios.post(`/portal/builds/${buildId}/set_status/`, data)).data
}
