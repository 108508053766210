import { EventInternalServicePromiseClient } from './grpc/devplay/coupon/internals/event_grpc_web_pb'
import { CouponInternalServicePromiseClient } from './grpc/devplay/coupon/internals/coupon_grpc_web_pb'
import { IssueInternalServicePromiseClient } from './grpc/devplay/coupon/internals/issue_grpc_web_pb'

import * as eventSvc from './grpc/devplay/coupon/internals/event_pb'
import * as couponSvc from './grpc/devplay/coupon/internals/coupon_pb'
import * as issueSvc from './grpc/devplay/coupon/internals/issue_pb'
import * as couponResource from './grpc/devplay/coupon/internals/coupon_resources_pb'
import 'google-protobuf/google/protobuf/timestamp_pb'
import { getAccessToken } from '@/misc/token'
import config from '../config'
import Message from 'element-ui/lib/message'
import { i18n } from '@/main'
import { StatusCode } from 'grpc-web'

const clients = {}

function getClient(env) {
  const endpoint = getCouponEndpoint(env)

  if (clients[endpoint]) {
    return clients[endpoint]
  }

  clients[endpoint] = new CouponClient(endpoint)
  return clients[endpoint]
}

function getCouponEndpoint(env) {
  let lowerEnv = env.toLowerCase()
  if (lowerEnv === 'sandbox') {
    lowerEnv = 'dev'
  }
  const endpoint = config.couponEndpoint[lowerEnv]
  if (!endpoint) {
    throw new Error('쿠폰 호스트를 찾을 수 없습니다.')
  }

  return endpoint
}

export function getCouponPageURL(env, gameCode) {
  let endpoint = getCouponEndpoint(env)

  // 브릭시티 팀의 요청에 따라 쿠폰 페이지 URL에 mars가 아닌 brixity를 사용
  if (gameCode === 'mars') {
    gameCode = 'brixity'
  }

  // PROD 환경의 경우, 외부 공개용 쿠폰 페이지 URL 사용
  let lowerEnv = env.toLowerCase()
  if (lowerEnv === 'prod') {
    endpoint = 'https://coupon.devplay.com'
  }

  return new URL(`coupon/${gameCode}/` + i18n.locale, endpoint.replace(/\/?$/, '/'))
}

const createMetadata = () => ({
  authorization: `Bearer ${getAccessToken()}`,
})

const errorInterceptor = function () {}
errorInterceptor.prototype.intercept = (request, invoker) => {
  return invoker(request)
    .then((response) => response)
    .catch(handleError)
}

const handleError = (e) => {
  let message
  switch (e.code) {
    case StatusCode.INVALID_ARGUMENT:
      message = i18n.t('message.OpsAlertCouponResponseInvalid')
      break
    case StatusCode.NOT_FOUND:
      if (e.message.includes('coupon code: not found')) {
        message = i18n.t('message.OpsAlertCouponResponseNotfoundCouponcode')
      } else {
        message = i18n.t('message.OpsAlertCouponResponseNotfound')
      }
      break
    case StatusCode.ALREADY_EXISTS:
      if (e.message.includes('word type coupon code')) {
        message = i18n.t('message.OpsAlertCouponResponseAlreadyexistsCouponcode')
      } else {
        message = i18n.t('message.OpsAlertCouponResponseAlreadyexists')
      }
      break
    case StatusCode.PERMISSION_DENIED:
      message = i18n.t('message.OpsAlertCouponResponsePermissiondenied')
      break
    case StatusCode.UNAUTHENTICATED:
      message = i18n.t('message.OpsAlertCouponResponseUnauthenticated')
      break
    case StatusCode.UNAVAILABLE:
      message = i18n.t('message.OpsAlertCouponResponseUnavailable')
      break
    default:
      message = i18n.t('message.OpsAlertCouponResponseUnknown')
  }

  Message({
    message: message,
    type: 'error',
    duration: 4000,
  })
  throw e
}

class CouponClient {
  constructor(endpoint) {
    const opts = { unaryInterceptors: [new errorInterceptor()] }
    this.eventService = new EventInternalServicePromiseClient(endpoint, null, opts)
    this.couponService = new CouponInternalServicePromiseClient(endpoint, null, opts)
    this.issueService = new IssueInternalServicePromiseClient(endpoint, null, opts)
  }
}

const convertEventProto = (event) => {
  const proto = new couponResource.Event()
  proto.setId(event.id)
  proto.setGameCode(event.gameCode)
  proto.setCouponName(event.couponName)
  proto.setCouponType(
    event.couponType === 'word'
      ? couponResource.CouponType.COUPON_TYPE_WORD
      : couponResource.CouponType.COUPON_TYPE_RANDOM,
  )
  proto.setNumberOfUsesPerMid(event.numberOfUsesPerMid)
  proto.setStartTime(convertTimestampProto(event.startTime))
  proto.setEndTime(convertTimestampProto(event.endTime))
  proto.setHasTargetCountries(event.hasTargetCountries)
  proto.setTargetCountriesList(event.targetCountries?.map(convertCountryProto))
  proto.setRewardsList(event.rewards?.map(convertRewardProto))
  proto.setInboxId(event.inboxId)
  if (event.titleMessage) {
    const msg = convertTranslatedMessageProto(event.titleMessage)
    msg.setTranslationMessageType(
      couponResource.TranslatedMessage.TranslationMessageType.TRANSLATION_MESSAGE_TYPE_TITLE,
    )
    proto.setTitleMessage(msg)
  }
  if (event.bodyMessage) {
    const msg = convertTranslatedMessageProto(event.bodyMessage)
    msg.setTranslationMessageType(
      couponResource.TranslatedMessage.TranslationMessageType.TRANSLATION_MESSAGE_TYPE_BODY,
    )
    proto.setBodyMessage(msg)
  }
  proto.setIssuesList(event.issues?.map(convertIssueProto))
  return proto
}

const convertTranslatedMessageProto = (msg) => {
  const proto = new couponResource.TranslatedMessage()
  proto.setTranslationKey(msg.translationKey)
  proto.setTranslationTextsList(msg.translationTexts?.map(convertTranslationTextProto))
  return proto
}

const convertTranslationTextProto = (text) => {
  const proto = new couponResource.TranslatedMessage.translation_text()
  proto.setLanguageCode(text.languageCode)
  proto.setText(text.text)
  return proto
}

const convertEventModel = (event) => ({
  ...event.toObject(),
  startTime: convertTimestampModel(event.getStartTime()),
  endTime: convertTimestampModel(event.getEndTime()),
  createTime: convertTimestampModel(event.getCreateTime()),
  issues: event.getIssuesList()?.map(convertIssueModel),
  rewards: event.getRewardsList()?.map(convertRewardModel),
  couponType: convertCouponTypeModel(event.getCouponType()),
  targetCountries: event.getTargetCountriesList()?.map((v) => v.getCode()),
})

const convertCouponTypeModel = (eventType) => {
  switch (eventType) {
    case couponResource.CouponType.COUPON_TYPE_RANDOM:
      return 'random'
    case couponResource.CouponType.COUPON_TYPE_WORD:
      return 'word'
    default:
      return 'unspecified'
  }
}

const convertCountryProto = (country) => {
  const proto = new couponResource.Country()
  proto.setCode(country)
  return proto
}

const convertRewardProto = (reward) => {
  const proto = new couponResource.Reward()
  proto.setCode(reward.code)
  proto.setName(reward.name)
  proto.setExtra(reward.extra)
  proto.setCount(reward.count)
  return proto
}

const convertRewardModel = (reward) => reward.toObject()

const convertIssueProto = (issue) => {
  const proto = new couponResource.Issue()
  proto.setId(issue.id)
  proto.setCouponCount(issue.couponCount)
  proto.setIssueComment(issue.issueComment)
  proto.setIssuer(issue.issuer || 'placeholder@foo.bar.xyz') // XXX: Should be read-only, isn't it?
  return proto
}

const convertIssueModel = (issue) => ({
  ...issue.toObject(),
  issueStatus: convertIssueStatusModel(issue.getIssueStatus()),
  issueTime: convertTimestampModel(issue.getIssueTime()),
})

const convertIssueStatusModel = (issueStatus) => {
  switch (issueStatus) {
    case couponResource.Issue.IssueStatus.ISSUE_STATUS_PROCEEDING:
      return 'proceeding'
    case couponResource.Issue.IssueStatus.ISSUE_STATUS_COMPLETE:
      return 'complete'
    default:
      return 'unspecified'
  }
}

const convertTimestampModel = (proto) => proto?.getSeconds() * 1000

const convertTimestampProto = (sec, nano = 0) => {
  const ts = new proto.google.protobuf.Timestamp()
  ts.setSeconds(sec)
  ts.setNanos(nano)
  return ts
}

export async function searchCouponEvents(env, gameCode, options) {
  const client = getClient(env)
  const req = new eventSvc.SearchEventsRequest()
  req.setGameCode(gameCode)
  req.setCouponName(options.couponName)
  req.setHideEnded(options.hideEnded)
  req.setPageToken(options.pageToken)
  req.setPageSize(options.pageSize)

  const resp = await client.eventService.searchEvents(req, createMetadata())
  return {
    events: resp.getEventsList()?.map(convertEventModel),
    nextPageToken: resp.getNextPageToken(),
  }
}

export async function getCouponEvent(env, eventId) {
  const client = getClient(env)
  const req = new eventSvc.GetEventRequest()
  req.setEventId(eventId)

  const resp = await client.eventService.getEvent(req, createMetadata())
  return convertEventModel(resp)
}

export async function getRandomTypeEventUsage(env, eventId) {
  const client = getClient(env)
  const req = new eventSvc.GetRandomTypeEventUsageRequest()
  req.setEventId(eventId)

  const resp = await client.eventService.getRandomTypeEventUsage(req, createMetadata())
  return resp.toObject()
}

export async function getWordTypeEventUsage(env, eventId) {
  const client = getClient(env)
  const req = new eventSvc.GetWordTypeEventUsageRequest()
  req.setEventId(eventId)

  const resp = await client.eventService.getWordTypeEventUsage(req, createMetadata())
  return resp.toObject()
}

export async function createCouponEvent(env, event, couponCode = '') {
  const client = getClient(env)
  const req = new eventSvc.CreateEventRequest()
  req.setEvent(convertEventProto(event))
  if (couponCode) req.setCouponCode(couponCode)

  const resp = await client.eventService.createEvent(req, createMetadata())
  return convertEventModel(resp)
}

export async function updateCouponEvent(env, eventId, eventUpdate) {
  const client = getClient(env)
  const req = new eventSvc.UpdateEventRequest()
  req.setEventId(eventId)
  req.setStartTime(convertTimestampProto(eventUpdate.startTime))
  req.setEndTime(convertTimestampProto(eventUpdate.endTime))
  req.setHasTargetCountries(eventUpdate.hasTargetCountries)
  req.setTargetCountriesList(eventUpdate.targetCountries?.map(convertCountryProto))
  req.setRewardsList(eventUpdate.rewards?.map(convertRewardProto))
  req.setInboxId(eventUpdate.inboxId)

  if (eventUpdate.titleMessage) {
    const msg = convertTranslatedMessageProto(eventUpdate.titleMessage)
    msg.setTranslationMessageType(
      couponResource.TranslatedMessage.TranslationMessageType.TRANSLATION_MESSAGE_TYPE_TITLE,
    )
    req.setTitleMessage(msg)
  }
  if (eventUpdate.bodyMessage) {
    const msg = convertTranslatedMessageProto(eventUpdate.bodyMessage)
    msg.setTranslationMessageType(
      couponResource.TranslatedMessage.TranslationMessageType.TRANSLATION_MESSAGE_TYPE_BODY,
    )
    req.setBodyMessage(msg)
  }

  const resp = await client.eventService.updateEvent(req, createMetadata())
  return convertEventModel(resp)
}

export async function issueAdditionalCoupon(env, eventId, couponCount, comment) {
  const client = getClient(env)
  const req = new issueSvc.CreateIssueRequest()

  req.setEventId(eventId)
  req.setIssue(convertIssueProto({ couponCount: couponCount, issueComment: comment }))

  const resp = await client.issueService.createIssue(req, createMetadata())
  return convertIssueProto(resp)
}

export async function getCouponCodes(env, issueId) {
  const client = getClient(env)
  const req = new couponSvc.BulkGetCouponCodesRequest()

  req.setIssueId(issueId)

  const resp = await client.couponService.bulkGetCouponCodes(req, createMetadata())
  return resp.getCouponCodesList()
}

export async function getCoupon(env, gameCode, couponCode) {
  const client = getClient(env)
  const req = new couponSvc.GetCouponUsageWithEventRequest()

  req.setGameCode(gameCode)
  req.setCouponCode(couponCode)

  const resp = await client.couponService.getCouponUsageWithEvent(req, createMetadata())
  const couponProto = resp.getCouponUsage()
  return {
    event: convertEventModel(resp.getEvent()),
    coupon: {
      couponCode: couponProto.getCouponCode(),
      mid: couponProto.getMid(),
      useTime: convertTimestampModel(couponProto.getUseTime()),
    },
  }
}

export async function bulkGetCouponUsagesWithEvents(env, gameCode, userInfo, type) {
  const client = getClient(env)
  const req = new couponSvc.BulkGetCouponUsagesWithEventsRequest()
  let userIdType
  if (type === 'mid') {
    userIdType = couponSvc.UserIdType.USER_ID_TYPE_MID
  } else if (type === 'email') {
    userIdType = couponSvc.UserIdType.USER_ID_TYPE_DEVPLAY_ID
  }
  req.setGameCode(gameCode)
  req.setUserIdType(userIdType)
  req.setId(userInfo)

  return (
    await client.couponService.bulkGetCouponUsagesWithEvents(req, createMetadata())
  ).getCouponUsagesWithEventsList()
}
