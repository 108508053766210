import * as api from '../api'
import Vue from 'vue'

function verToInt(v) {
  const vWeight = 2000
  const suffixExcluded = v.match(/(\d+\.?)+/g)[0]
  const numbers = suffixExcluded.match(/\d+/g).map((iStr) => parseInt(iStr))

  let sum = 0
  for (const n of numbers) {
    sum *= vWeight
    sum += n
  }

  return sum
}

export default {
  state: {
    releases: {},
  },
  getters: {
    sortedReleases: (state) => (gameId) =>
      Object.values(state.releases[gameId] || {}).sort(
        (a, b) => verToInt(b.version) - verToInt(a.version),
      ),
  },
  actions: {
    async getReleasesByGameId({ commit }, { gameId }) {
      const releases = await api.getReleasesByGameId(gameId)
      commit('addReleases', releases)
      return releases
    },
    async getRelease({ commit }, { releaseId }) {
      const r = await api.getRelease(releaseId)
      r.detail = true
      commit('addReleases', [r])
      return r
    },
    async postRelease({ commit }, { game, platform, store, version }) {
      const result = await api.postRelease(game, platform, store, version)
      result.detail = true
      commit('addReleases', [result])
      return result
    },
    async getReleaseStage({ commit }, { releaseStage }) {
      const r = await api.getReleaseStage(releaseStage.id)
      commit('addReleaseStage', r)
      return r
    },
    async patchReleaseStage({ commit }, { id, serverFarmId, resourceIds }) {
      const patchedReleaseStage = await api.patchReleaseStage(id, serverFarmId, resourceIds)
      commit('addReleaseStage', patchedReleaseStage)
      return patchedReleaseStage
    },
    async requestQAForRelease({ commit }, { release, params }) {
      const result = await api.requestQAForRelease(release, params)
      result.detail = true
      commit('addReleases', [result])
      return result
    },
    async promoteRelease({ commit }, { release, params }) {
      const result = await api.promoteRelease(release, params)
      result.detail = true
      commit('addReleases', [result])
      return result
    },
    async rejectRelease({ commit }, { release, params }) {
      const result = await api.rejectRelease(release, params)
      result.detail = true
      commit('addReleases', [result])
      return result
    },
    async deleteRelease({ commit }, { release }) {
      await api.deleteRelease(release)
      commit('deleteRelease', release)
    },
  },
  mutations: {
    addReleases(state, releases) {
      const tmpStore = {}

      for (const release of releases) {
        if (tmpStore[release.game] == null) {
          tmpStore[release.game] = {}
        }
        tmpStore[release.game][release.id] = release
      }

      for (const [gameId, releasesByGameId] of Object.entries(tmpStore)) {
        if (state.releases[gameId] == null) {
          Vue.set(state.releases, gameId, {})
        }
        const existing = state.releases[gameId]
        state.releases[gameId] = Object.assign({}, existing, releasesByGameId)
      }
    },
    addReleaseStage(state, releaseStage) {
      const gameId = releaseStage.game
      const releaseId = releaseStage.release

      const stages = state.releases[gameId][releaseId]['stages']
      const rsIndex = stages.findIndex((rs) => rs.env === releaseStage.env)

      if (rsIndex === -1) {
        console.error('release not found')
        return
      }

      const previous = stages[rsIndex]
      stages[rsIndex] = Object.assign(previous, releaseStage)
    },
    deleteRelease(state, release) {
      Vue.delete(state.releases[release.game], release.id)
    },
  },
}
