"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiVersion = void 0;
const etc_1 = require("client/api/etc");
exports.apiVersion = {
    state: {
        lastCommit: '',
        lastCommitter: '',
        buildDate: '',
        env: '',
        envSet: '',
        release: '',
    },
    actions: {
        getApiVersion(_a) {
            return __awaiter(this, arguments, void 0, function* ({ commit }) {
                const apiVersion = yield (0, etc_1.getApiVersion)();
                commit('setApiVersion', apiVersion);
            });
        },
    },
    mutations: {
        setApiVersion(state, { last_commit, last_committer, build_date, env, env_set, release }) {
            state.lastCommit = last_commit;
            state.lastCommitter = last_committer;
            state.buildDate = build_date;
            state.env = env;
            state.envSet = env_set;
            state.release = release;
        },
    },
};
