import * as api from '../api'
import localforage from 'localforage'

export default {
  state: {
    selecting: 0,
    env: null,
    envs: [],
  },
  actions: {
    async loadEnvironment({ commit }) {
      const env = await localforage.getItem('environment')
      if (env) {
        commit('setEnvironment', env)
      }
    },
    async saveEnvironment({ commit }, env) {
      const normalizedEnv = env.toUpperCase() === 'DEV' ? 'SANDBOX' : env.toUpperCase()
      localforage.setItem('environment', normalizedEnv)
      commit('setEnvironment', normalizedEnv)
    },
    async getEnvironments({ commit, state, dispatch }) {
      await dispatch('loadEnvironment')
      if (state.envs.length === 0) {
        const environments = await api.getEnvironments()
        commit('setEnvironments', environments)
        if (!state.env && environments.length > 0) {
          commit('setEnvironment', environments[0].name)
        }
      }
    },
  },
  mutations: {
    setEnvironments(state, envs) {
      state.envs = envs.sort(({ order: l }, { order: r }) => l - r)
    },
    setEnvironment(state, env) {
      // 다른 컴포넌트에서 env가 선택되는 이벤트를 받을 수 있게 하기 위한 장치.
      // env를 watch할 경우 같은 값을 선택하면 이벤트를 받을 수 없어서 selecting을 따로 만듦.
      state.selecting = 1 - state.selecting
      state.env = env
    },
  },
  getters: {
    getEnvByName: (state) => (envName) => {
      return state.envs.find((e) => e.name === envName)
    },
    getEnvById: (state) => (envId) => {
      return state.envs.find((e) => e.id === envId)
    },
  },
}
