const trimInput = () => {
  const inputHandler = (event) => {
    const { value } = event.target
    if (typeof value !== 'string') {
      return
    }
    const trimmedValue = event.target.value?.trim()
    if (event.target.value !== trimmedValue) {
      event.target.value = trimmedValue
    }
  }

  document.addEventListener('focusout', inputHandler)
}

export default trimInput
