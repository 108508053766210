import axios from 'axios'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import decamelizeKeysDeep from 'decamelize-keys-deep'

export async function getMaintenance(game, env) {
  const resp = await axios.get(`/portal/maintenance/?game=${game}&env=${env}`)
  return camelcaseKeysDeep(resp.data)
}

export async function startMaintenance(game, env, stores) {
  const data = {
    game,
    env,
    stores,
  }
  const resp = await axios.post('/portal/maintenance/', decamelizeKeysDeep(data))
  return camelcaseKeysDeep(resp.data)
}

export async function cancelMaintenance(game, env, stores) {
  let queryString = ''
  for (let store of stores) {
    queryString += `store=${store}&`
  }
  const resp = await axios.delete(
    `/portal/maintenance/env/${env}/game/${game}?${queryString.slice(0, -1)}`,
  )
  return camelcaseKeysDeep(resp.data)
}

// endDt는 unix timestamp 값이이다 (Ex: new Date().getTime())
export async function changeMaintenanceInfo(
  game,
  env,
  stores,
  endDt,
  isOtherPhrase,
  translationTexts,
  translationExtra,
) {
  const data = {
    game,
    env,
    stores,
    endDt,
    isOtherPhrase,
    extras: translationExtra,
  }
  if (isOtherPhrase) {
    data.translationTexts = translationTexts
  }
  data.hasExtra = translationExtra.length > 0
  const resp = await axios.post('/portal/maintenance_info/', decamelizeKeysDeep(data))
  return camelcaseKeysDeep(resp.data)
}

export async function getMaintenanceHistories(game, env, offset, limit) {
  const params = {
    game,
    env,
    offset,
    limit,
  }
  const resp = await axios.get('/portal/maintenance_history/', { params })
  return camelcaseKeysDeep(resp.data)
}
