import axios from 'axios'

export async function getECRAuth(game, type, version) {
  const opt = {
    params: { game, type, version },
  }

  return (await axios.get('/portal/ecr/auth/', opt)).data
}

export async function checkECRImage(game, type, tag) {
  const opt = {
    params: { game, type, tag },
  }

  return (await axios.get('/portal/ecr/image/', opt)).data
}

export async function deleteECRImage(game, type, tag, digest) {
  const data = { game, type, tag, digest }
  return (await axios.post('/portal/ecr/image/', data)).data
}

export async function copyServerImages({ game, images, envs }) {
  const params = {
    game: game.game_code,
    images: images.map((i) => i.id),
    envs: envs,
  }
  return (await axios.post('/portal/ecr/image_copy/', params)).data
}
