import * as api from '../api'
import Vue from 'vue'

function initialState() {
  return {
    common: [],
    game: {},
    uploading: false,
  }
}

export default {
  state: initialState,
  actions: {
    async getWordFilterUpdateHistories({ commit }, { gameCode = null }) {
      const histories = await api.getWordFilterUpdateHistories(gameCode)
      commit('updateHistories', { gameCode, histories })

      return histories
    },

    async getWordFilterUpdateHistory({ commit }, { req_uuid, gameCode = null }) {
      const history = await api.getWordFilterUpdateHistory(req_uuid, gameCode)
      commit('updateHistory', { gameCode, req_uuid, history })

      return history
    },

    async uploadWordFilterXlsx({ state, dispatch }, { gameCode = null, file, desc }) {
      const newHistory = await api.uploadWordFilterXlsx(file, desc, gameCode)
      dispatch('getWordFilterUpdateHistories', { gameCode })
      return newHistory
    },

    async setUploading({ commit }, uploading) {
      commit('setUploading', uploading)
    },
  },
  mutations: {
    updateHistories(state, { gameCode, histories }) {
      if (gameCode == null) {
        Vue.set(state, 'common', histories)
      } else {
        Vue.set(state.game, gameCode, histories)
      }
    },

    updateHistory(state, { gameCode, req_uuid, history }) {
      const hs = gameCode === null ? state.common : state.game[gameCode]
      const index = hs.findIndex((item) => item.req_uuid === req_uuid)
      if (index !== -1) {
        Vue.set(hs, index, history)
      } else {
        hs.splice(0, 0, history)
      }
    },

    setUploading(state, uploading) {
      state.uploading = uploading
    },
  },
}
