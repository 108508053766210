import axios from 'axios'

class Config {
  constructor() {
    this.apiEndpoint = 'http://localhost:8000'
    this.gamecenterEndpoint = 'http://gamecenter.local.devplay.io'
    this.pushApiEndpoint = {
      dev: 'http://api.push.local.devplay.io:9098',
      qa: 'http://api.push.local.devplay.io:9098',
      stg: 'http://api.push.local.devplay.io:9098',
      prod: 'http://api.push.local.devplay.io:9098',
    }
    this.itembrokerEndpoint = {
      dev: 'http://api.itembroker.local.devplay.io:9092',
      qa: 'http://api.itembroker.local.devplay.io:9092',
      stg: 'http://api.itembroker.local.devplay.io:9092',
      prod: 'http://api.itembroker.local.devplay.io:9092',
    }
    this.promotionEndpoint = {
      dev: 'http://api.promotion.local.devplay.io:9095',
      qa: 'http://api.promotion.local.devplay.io:9095',
      stg: 'http://api.promotion.local.devplay.io:9095',
      prod: 'http://api.promotion.local.devplay.io:9095',
    }
    this.couponEndpoint = {
      dev: 'http://coupon.local.devplay.io:9092',
      qa: 'http://coupon.local.devplay.io:9092',
      stg: 'http://coupon.local.devplay.io:9092',
      prod: 'http://coupon.local.devplay.io:9092',
    }
    this.keycloakUrl = 'https://auth.devsisters.cloud/auth'
    this.keycloakRealmName = 'devsisters'
    this.keycloakClientId = 'portal-local'
  }

  async loadRemoteConfig() {
    try {
      const resp = await axios.create().get('/config.json')
      const data = resp.data

      this.apiEndpoint = data['api_endpoint']
      this.gamecenterEndpoint = data['gamecenter_endpoint']
      this.pushApiEndpoint = {
        dev: data['dev_pushapi_endpoint'],
        qa: data['qa_pushapi_endpoint'],
        stg: data['stg_pushapi_endpoint'],
        prod: data['prod_pushapi_endpoint'],
      }
      this.itembrokerEndpoint = {
        dev: data['dev_itembroker_endpoint'],
        qa: data['qa_itembroker_endpoint'],
        stg: data['stg_itembroker_endpoint'],
        prod: data['prod_itembroker_endpoint'],
      }
      this.promotionEndpoint = {
        dev: data['dev_promotion_endpoint'],
        qa: data['qa_promotion_endpoint'],
        stg: data['stg_promotion_endpoint'],
        prod: data['prod_promotion_endpoint'],
      }
      this.couponEndpoint = {
        dev: data['dev_coupon_endpoint'],
        qa: data['qa_coupon_endpoint'],
        stg: data['stg_coupon_endpoint'],
        prod: data['prod_coupon_endpoint'],
      }
      this.keycloakUrl = data['keycloak_url'] || this.keycloakUrl
      this.keycloakRealmName = data['keycloak_realm_name'] || this.keycloakRealmName
      this.keycloakClientId = data['keycloak_client_id']
    } catch {
      document.body.innerHTML =
        '<b style="display: block; margin: 20px auto; text-align: center;">웹 페이지 설정 정보를 찾을 수 없습니다. 배포 설정이 올바르지 않은 것 같습니다.</b>'
      throw new Error('Failed to load remote config. Cannot start application.')
    }
  }
}

export default new Config()
