import axios from 'axios'
import camelcaseKeysDeep from 'camelcase-keys-deep'
import decamelizeKeysDeep from 'decamelize-keys-deep'
import { AnalyticsReport } from '../models/analytics_report'

export async function listAnalyticsReport(gameCode) {
  const res = camelcaseKeysDeep(
    (await axios.get(`/portal/analytics_report/?game=${gameCode}`)).data,
  )
  return res.map((r) => new AnalyticsReport(r))
}

export async function getAnalyticsReport(key, gameCode) {
  const res = camelcaseKeysDeep(
    (await axios.get(`/portal/analytics_report/${key}/?game=${gameCode}`)).data,
  )
  return new AnalyticsReport(res)
}

export async function postAnalyticsReport(analyticsReport, gameCode) {
  analyticsReport.game = gameCode
  const res = camelcaseKeysDeep(
    (
      await axios.post(
        `/portal/analytics_report/?game=${gameCode}`,
        decamelizeKeysDeep(analyticsReport),
      )
    ).data,
  )
  return new AnalyticsReport(res)
}

export async function putAnalyticsReport(analyticsReport, gameCode) {
  analyticsReport.game = gameCode
  const res = camelcaseKeysDeep(
    (
      await axios.put(
        `/portal/analytics_report/${analyticsReport.key}/?game=${gameCode}`,
        decamelizeKeysDeep(analyticsReport),
      )
    ).data,
  )
  return new AnalyticsReport(res)
}

export async function deleteAnalyticsReport(key, gameCode) {
  await axios.delete(`/portal/analytics_report/${key}/?game=${gameCode}`)
}

export async function getAnalyticsReportUrl(reportKey, gameCode, params) {
  const opt = {
    params: Object.assign(JSON.parse(JSON.stringify(params)), { key: reportKey, game: gameCode }),
  }

  return (await axios.get('/portal/analytics_report_signature/', opt)).data
}
