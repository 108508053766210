import * as api from '../api'
import Vue from 'vue'
import { cmp } from '../utils'
import { getNow } from '../dates'
import localforage from 'localforage'

function defaultImagePage() {
  return {
    total: 1,
    page: 1,
    loading: false,
    images: [],
    latest: [],
  }
}

export default {
  state: {
    meta: {
      imagePageSize: 10,
    },
    images: {
      // type: { page: pageNum, loading: false, images: [], }, ...
    },
    imageTypes: {},
    serverFarms: {},
    mysqlDDLs: {},
    couchbaseViewFiles: {},
    databaseSettings: {},
    serverFarmSortBy: null,
  },
  getters: {
    mysqlDDLMap: (state) => (gameId) => state.mysqlDDLs[gameId] || {},
    couchbaseViewFileMap: (state) => (gameId) => state.couchbaseViewFile[gameId] || {},
    sortedMysqlDDLs: (state) => (gameId) =>
      Object.values(state.mysqlDDLs[gameId] || {}).sort(
        (a, b) => -cmp(a.uploaded_at, b.uploaded_at),
      ),
    sortedCouchbaseViewFiles: (state) => (gameId) =>
      Object.values(state.couchbaseViewFiles[gameId] || {}).sort(
        (a, b) => -cmp(a.uploaded_at, b.uploaded_at),
      ),
    serverFarmSortBy: (state) => state.serverFarmSortBy,
  },
  actions: {
    async postServerImage({ dispatch, commit }, { game, type, tag }) {
      await api.postServerImage(game.game_code, type, tag)
      // 첫 페이지로 간다
      dispatch('getServerImagePage', { game, type, page: 1 })
    },
    async getServerImagePage({ state, commit }, { game, type, page }) {
      // gameCode, type 에 해당하는 게임 페이지를 업데이트함. 사용자가 보고 있는 페이지가 바뀌는 행위.
      // page 는 1-based indexing 임에 주의!
      commit('toggleServerImageLoading', { game, type })

      const offset = state.meta.imagePageSize * (page - 1)
      const result = await api.getServerImagesForGame(
        game.game_code,
        type,
        state.meta.imagePageSize,
        offset,
      )
      commit('updateServerImagePage', {
        game,
        type,
        page,
        total: result.count,
        images: result.results,
      })

      if (page === 1) {
        commit('updateServerImageLatest', { game, type, images: result.results })
      }

      commit('toggleServerImageLoading', { game, type })
      return result
    },
    async getServerImageTypesForGame({ commit }, { gameId }) {
      const types = await api.getServerImageTypesForGame(gameId)
      for (const type of types) {
        commit('addServerImageType', type)
      }
      return types
    },
    async getServerFarm({ commit }, { serverFarmId }) {
      const server = await api.getServerFarm(serverFarmId)
      commit('addServerFarm', server)
      return server
    },
    async getServerFarmsForGame({ commit }, { gameCode }) {
      const servers = await api.getServerFarmsForGame(gameCode)
      // 먼저 해당 게임에 대한 서버팜 스토어를 비워서, 삭제된 서버팜을 처리할 수 있도록 한다
      if (servers.length) {
        // api는 gameCode로 부르는데 스토어에는 gameId를 키로 쓰기 때문에 변환이 필요
        const gameId = servers[0].game
        commit('clearServerFarm', gameId)

        for (const server of servers) {
          commit('addServerFarm', server)
        }
      }
      return servers
    },
    async deleteServerFarm({ commit }, { gameId, serverFarmId }) {
      await api.deleteServerFarm(serverFarmId)
      commit('terminateServerFarm', { gameId, serverFarmId })
    },
    async postServerFarm({ commit }, { data }) {
      const newServerFarm = await api.postServerFarm(data)
      commit('addServerFarm', newServerFarm)
    },
    async patchServerFarm({ commit }, { data }) {
      const updatedServerFarm = await api.patchServerFarm(data)
      commit('editServerFarm', updatedServerFarm)
    },
    // 위의 patch 는 스토브가 띄운 서버들에 대한 패치. 아래의 update 는 서버팜의 일부 변경 가능한 필드 partial update.
    async updateServerFarm({ commit }, { serverFarm, data }) {
      const updatedServerFarm = await api.updateServerFarm(serverFarm.id, data)
      commit('editServerFarm', updatedServerFarm)
      return updatedServerFarm
    },
    async getServerMysqlDDLForGame({ commit }, { gameId }) {
      const result = await api.getServerMysqlDDLForGame(gameId)
      for (const newMysqlDDL of result) {
        commit('addMysqlDDL', newMysqlDDL)
      }
    },
    async postServerMysqlDDL({ commit }, { gameId, newFile }) {
      const newMysqlDDL = await api.postServerMysqlDDL(gameId, newFile)
      commit('addMysqlDDL', newMysqlDDL)
    },
    async getServerCouchbaseViewForGame({ commit }, { gameId }) {
      const result = await api.getServerCouchbaseViewForGame(gameId)
      for (const view of result) {
        commit('addCouchbaseViewFile', view)
      }
    },
    async postServerCouchbaseView({ commit }, { gameId, newFile }) {
      const newCouchbaseView = await api.postServerCouchbaseView(gameId, newFile)
      commit('addCouchbaseViewFile', newCouchbaseView)
    },
    async getServerDatabaseSettingsForGame({ commit }, { gameId }) {
      const databaseSettings = await api.getServerDatabaseSettingsForGame(gameId)
      commit('addServerDatabaseSettings', databaseSettings)
      return databaseSettings
    },
    async postServerDatabaseSetting({ commit }, { form }) {
      const result = await api.postServerDatabaseSetting(form)
      commit('addServerDatabaseSettings', [result])
      return result
    },
    async patchServerDatabaseSetting({ commit }, { databaseSetting, form }) {
      const result = await api.patchServerDatabaseSetting(databaseSetting.id, form)
      commit('addServerDatabaseSettings', [result])
      return result
    },
    async updateServerFarmSortBy({ commit }, sortBy) {
      await localforage.setItem('serverFarmSortBy', sortBy)
      commit('updateServerFarmSortBy', sortBy)
    },
    async loadServerFarmSortBy({ commit }) {
      const sortBy = (await localforage.getItem('serverFarmSortBy')) || 'sortByCreatedAtDsc'
      commit('updateServerFarmSortBy', sortBy)
    },
  },
  mutations: {
    toggleServerImageLoading(state, { game, type }) {
      if (!state.images[game.id]) Vue.set(state.images, game.id, {})
      if (!state.images[game.id][type]) Vue.set(state.images[game.id], type, defaultImagePage())

      state.images[game.id][type].loading = !state.images[game.id][type].loading
    },
    updateServerImagePage(state, { game, type, page, total, images }) {
      if (!state.images[game.id]) Vue.set(state.images, game.id, {})
      if (!state.images[game.id][type]) Vue.set(state.images[game.id], type, defaultImagePage())

      state.images[game.id][type].page = page
      state.images[game.id][type].total = total
      state.images[game.id][type].images = images
    },
    updateServerImageLatest(state, { game, type, images }) {
      if (!state.images[game.id]) Vue.set(state.images, game.id, {})
      if (!state.images[game.id][type]) Vue.set(state.images[game.id], type, defaultImagePage())

      state.images[game.id][type].latest = images
    },
    addServerImageType(state, serverImageType) {
      if (!state.imageTypes[serverImageType.game]) {
        Vue.set(state.imageTypes, serverImageType.game, {})
      }
      Vue.set(state.imageTypes[serverImageType.game], serverImageType.id, serverImageType)
    },
    addServerFarm(state, serverFarm) {
      if (!state.serverFarms[serverFarm.game]) {
        Vue.set(state.serverFarms, serverFarm.game, {
          [serverFarm.id]: serverFarm,
        })
      } else if (state.serverFarms[serverFarm.game].hasOwnProperty(serverFarm.id)) {
        Object.assign(state.serverFarms[serverFarm.game][serverFarm.id], serverFarm)
      } else {
        Vue.set(state.serverFarms[serverFarm.game], serverFarm.id, serverFarm)
      }
    },
    editServerFarm(state, updatedServerFarm) {
      Vue.set(state.serverFarms[updatedServerFarm.game], updatedServerFarm.id, updatedServerFarm)
    },
    terminateServerFarm(state, { gameId, serverFarmId }) {
      const serverFarm = state.serverFarms[gameId][serverFarmId]
      serverFarm.status = 'terminating'
      /* eslint-disable */
      serverFarm.modified_at = getNow()
      /* eslint-enable */
    },
    clearServerFarm(state, gameId) {
      Vue.set(state.serverFarms, gameId, {})
    },
    addMysqlDDL(state, mysqlDDL) {
      if (!state.mysqlDDLs[mysqlDDL.game]) {
        Vue.set(state.mysqlDDLs, mysqlDDL.game, {})
      }
      Vue.set(state.mysqlDDLs[mysqlDDL.game], mysqlDDL.id, mysqlDDL)
    },
    addCouchbaseViewFile(state, couchbaseViewFile) {
      if (!state.couchbaseViewFiles[couchbaseViewFile.game]) {
        Vue.set(state.couchbaseViewFiles, couchbaseViewFile.game, {})
      }
      Vue.set(
        state.couchbaseViewFiles[couchbaseViewFile.game],
        couchbaseViewFile.id,
        couchbaseViewFile,
      )
    },
    addServerDatabaseSettings(state, serverDatabaseSettings) {
      for (const ds of serverDatabaseSettings) {
        if (!state.databaseSettings[ds.game_id]) Vue.set(state.databaseSettings, ds.game_id, {})
        if (!state.databaseSettings[ds.game_id][ds.id]) {
          Vue.set(state.databaseSettings[ds.game_id], ds.id, {})
        }
        Object.assign(state.databaseSettings[ds.game_id][ds.id], ds)
      }
    },
    updateServerFarmSortBy(state, sortBy) {
      state.serverFarmSortBy = sortBy
    },
  },
}
