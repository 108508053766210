import Cookies from 'cookies-js'
import { getNow } from '../dates'

export function setAccessToken(token) {
  sessionStorage.setItem('portal-keycloak-token', token)

  // XXX: file download 로직을 처리하기 위해 쿠키도 어쩔 수 없이 같이 저장해야 한다...
  const expires = getNow().add(30, 'minutes').toDate()

  for (let i = 0; ; i++) {
    const tokenPiece = token.substring(i * 3500, (i + 1) * 3500)
    const tokenName = `accesstoken${i}`

    if (tokenPiece !== '') {
      Cookies.set(tokenName, tokenPiece, {
        expires: expires,
        secure: process.env.NODE_ENV === 'production',
      })
    } else if (Cookies.get(tokenName)) {
      // XXX: 토큰 조각들을 다 저장했다 하더라도 새 토큰이 이전 토큰 길이보다 짧을 경우
      // 이전 토큰의 잔여물이 남을 수 있어 이전 토큰 조각을 모두 삭제해주어야 한다.
      Cookies.expire(tokenName)
    } else {
      break
    }
  }
}

export function getAccessToken() {
  return sessionStorage.getItem('portal-keycloak-token')
}
