<template>
  <div id="wrapper" :class="{ 'hide-navbar': hideSidebar }">
    <!--
    TODO(changje): Portal API서버와 FrontEnd를 분리하면서 API Server의 버전업은 새로고침을 유도할 필요가 없어졌다.
    이에 따라 FrontEnd Version을 Fetching 할 새로운 방법이 필요하다
    -->
    <!--<el-alert-->
    <!--class="top-alert"-->
    <!--v-if="needsUpdate"-->
    <!--closable-->
    <!--show-icon-->
    <!--&gt;-->
    <!--포탈이 업데이트되었습니다. 새로고침을 눌러 새 버전을 이용해 주세요 :)-->
    <!--</el-alert>-->
    <router-view class="block" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      hideSidebar: (state) => state.viewModes.hideSidebar,
      portalEnv: (state) => state.apiVersion.env,
    }),
    ...mapGetters(['getGameByGameCode', 'gameCode']),
    game() {
      return this.getGameByGameCode(this.gameCode)
    },
  },
  async created() {
    await this.loadEnvironment()
    await this.getUserInfo()
    await this.loadGameCode()
  },
  async mounted() {
    await this.loadGame()
    await this.getApiVersion()
  },
  methods: {
    ...mapActions([
      'getUserInfo',
      'getGame',
      'startLoading',
      'doneLoading',
      'loadGameCode',
      'saveGameCode',
      'loadEnvironment',
      'getApiVersion',
    ]),
    async loadGame() {
      if (this.gameCode) {
        this.saveGameCode(this.gameCode)
        this.startLoading()
        await this.getGame({ gameCode: this.gameCode })
        this.doneLoading()
      }
    },
  },
  watch: {
    async gameCode() {
      await this.loadGame()
    },
  },
}
</script>

<style lang="scss" scoped>
#wrapper {
  height: 100%;
}
.top-alert {
  border-radius: 0;
  padding-top: 12px;
  padding-bottom: 12px;
}

.el-alert--info.is-light {
  background-color: #fdf6ec;
  color: #e6a23c;

  ::v-deep .el-alert__description {
    color: #e6a23c;
    font-size: 18px !important;
    font-weight: bold;
    margin-top: 0;
  }
}
</style>
